import React, { useEffect, useState } from "react";
import Styles from "./ServiceProviderCard.module.css";
import {
  followUser,
  getFreelancerProfile,
} from "../../../../redux/actions/FreelancerProfile";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { getRole } from "../../../../common/commonFunc";
import { Typography } from "@mui/material";


const DetailsCard = ({ serviceProviderDetails, providerID }: any) => {
  console.log(
    "➡️   ~ DetailsCard ~ serviceProviderDetails:",
    serviceProviderDetails
  );
  const [openDrawer, setopenDrawer] = React.useState<any>(false);
  const dispatch = useDispatch<any>();
  const displayName_supplier = serviceProviderDetails?.freelancer_info?.display_name
    ? serviceProviderDetails?.freelancer_info?.display_name
    : `${serviceProviderDetails?.freelancer_info?.first_name} ${serviceProviderDetails?.freelancer_info?.last_name}`;
  const initial_supplier = serviceProviderDetails?.freelancer_info?.first_name.charAt(0);
  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(providerID, (value: any) => {
        setopenDrawer(value);
      })
    );
  };
  const followFunctionHandler = async () => {
    await dispatch(followUser(providerID, "freelancer"));
    await dispatch(getFreelancerProfile(providerID, () => {}));
  };
  return (
    <>
    <Typography fontWeight={"bold"}>Sold by : {serviceProviderDetails?.supplier_info?.supplier_display_name ??
     serviceProviderDetails?.supplier_info?.supplier_business_name} </Typography>
    <div className={Styles.cardContainer}>
      <div className={Styles.profileSection}>
        <div
          onClick={onFreelancerProfileClickHandler}
          className={Styles.profileImage}
        >
          {serviceProviderDetails?.freelancer_info?.profile_pic !== null &&
          serviceProviderDetails?.freelancer_info?.profile_pic !== undefined &&
          serviceProviderDetails?.freelancer_info?.profile_pic !== "" &&
          serviceProviderDetails?.freelancer_info?.profile_pic !== "null" &&
          serviceProviderDetails?.freelancer_info?.profile_pic !== "NULL"
           ? (
            <img
              src={serviceProviderDetails?.freelancer_info?.profile_pic}
              alt={displayName_supplier}
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          ) : (
            <span>{initial_supplier}</span>
          )}
        </div>
        <div className={Styles.profileName}>
          Collaborated with <span className={Styles.displayName}>{displayName_supplier}</span>
        </div>
      </div>
      <button
        onClick={followFunctionHandler}
        className={Styles.followButton}
        disabled={userRole === "guest" ? true : false}
      >
        Follow
      </button>
    </div>
    </>
  );
};

export default DetailsCard;
