import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Row,
    Card,
    Form,
    Input,
    message,
} from "antd";
import {
    EyeOutlined,
    EditOutlined,
    SaveOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { _PATCH, _POST } from "../../../common/commonFunc";
import styles from "./SupplierAdditonalInformation.module.css";
import FileUpload from "../../../components/InputComponents/FileUpload";



const SupplierAdditionalInfo = ({ userInfo, onSave, back }: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({});
    const [form] = Form.useForm();
    const [profileImage, setProfileImage] = useState<any>(null);
    console.log("➡️   ~ PersonalInfoSupplier ~ profileImage:", profileImage);
    const [backSideImage, setBackSideImage] = useState<any>(null);
    console.log("➡️   ~ PersonalInfoSupplier ~ backSideImage:", backSideImage);
    const [profileImagePreviews, setProfileImagePreviews] = useState<any>([]);


    useEffect(() => {
        if (userInfo) {
            const initialFormValues = {
                displayName: userInfo.supplier_display_name,
                courierCharges: userInfo.courier_charges_per_delivery,
                // courierCurrency: userInfo.courier_charges_currency,
                profileImage: userInfo.supplier_display_image,
            };

            form.setFieldsValue(initialFormValues);
            setInitialValues(initialFormValues);
            setProfileImagePreviews(
                initialFormValues.profileImage
                    ? [initialFormValues.profileImage]
                    : []
            );
        }
    }, [userInfo, form]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        form.setFieldsValue(initialValues);
        setIsEditing(false);
    };

    const uploadImages = async () => {
        let uploadedUrls: { profile_image?: string } = {};

        if (profileImage) {
            const formData = new FormData();

            if (profileImage) {
                formData.append("images", profileImage.profileImage);
            }



            try {
                const response: any = await _POST(
                    `uploadImagesToS3/supplier_document`,
                    formData,
                    true
                );

                if (response?.data?.length > 0) {
                    const [firstImageUrl] = response.data;

                    if (profileImage) {
                        uploadedUrls = {
                            profile_image: firstImageUrl,
                        };
                    } else if (profileImage) {
                        uploadedUrls.profile_image = firstImageUrl;
                    }
                }
            } catch (error) {
                console.error("Error uploading image:", error);
                message.error("Failed to upload image.");
            }
        }

        return uploadedUrls;
    };

    const handleSave = async () => {
        try {
            const values = await form.validateFields();


            const existingProfileImage = initialValues.profileImage;

            if (!profileImage && !existingProfileImage) {
                message.error("profile image is required");
                return;
            }
            const uploadedImages = await uploadImages();

            const payload = {
                supplier_display_name: values.displayName,
                courier_charges_per_delivery: parseInt(values.courierCharges),
                courier_charges_currency: "BHD",
                supplier_display_image:
                    uploadedImages.profile_image || initialValues.profileImage,

            };

            const response: any = await _PATCH(
                `userProfile/supplier/details`,
                payload,
                true
            );

            if (response?.status === true && response?.statusCode === 200) {
                message.success("Personal Information updated successfully");
                onSave();
                setIsEditing(false);
                onSave();
            } else {
                message.error("Failed to update Personal Information");
            }
        } catch (error) {
            console.error("Error updating Personal Information:", error);
            message.error("An error occurred while updating Personal Information");
        }
    };

    return (
        <Card
            title="Supplier Profile Information"
            extra={
                <div style={{ display: "flex", gap: "10px" }}>
                    {isEditing && (
                        <Button
                            icon={<CloseOutlined />}
                            onClick={handleCancel}
                            className={styles.saveBtn}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        type="primary"
                        icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                        onClick={isEditing ? handleSave : handleEditClick}
                        className={`${styles.saveBtn} ${isEditing ? styles.saveSmallBtn : ""
                            }`}
                    >
                        {isEditing ? "Save" : "Edit"}
                    </Button>
                </div>
            }
            className={styles.businessCard}
        >
            <Form
                style={{ marginTop: "0 !important" }}
                form={form}
                layout="vertical"
                initialValues={initialValues}
                className={styles.form}
            >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="displayName"
                            label="Display Name"
                            rules={[
                                {
                                    message: "Please enter supplier display name",
                                },
                            ]}
                        >
                            <Input
                                disabled={!isEditing}
                                className={
                                    isEditing ? styles.loginInput : styles.loginInputDisable
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="courierCharges"
                            label="Supplier Courier Charges"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter supplier courier charges",
                                },
                                {
                                    pattern: /^\d+(\.\d{1,3})?$/,
                                    message: "Charges must be a number with up to three decimal places",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                addonBefore="BHD"
                                disabled={!isEditing}
                                className={
                                    isEditing ? styles.loginInput : styles.loginInputDisable
                                }
                                onChange={(e) => {
                                    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
                                    const formattedValue = numericValue.match(/^\d*\.?\d{0,3}/)?.[0] || "";
                                    e.target.value = formattedValue;
                                }}
                            />
                        </Form.Item>
                    </Col>




                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="profileImage" label={<p>Business Profile Image</p>}>
                            <FileUpload
                                title1="Upload Image"
                                description="PDF, JPG, PNG file with max size of 10mb."
                                setData={setProfileImage}
                                name="profileImage"
                                existingPreviews={profileImagePreviews}
                                setExistingPreviews={setProfileImagePreviews}
                                disabled={!isEditing}
                                alllowAll
                            />
                        </Form.Item>
                    </Col>


                </Row>

            </Form>
        </Card>
    );
};

export default SupplierAdditionalInfo;
