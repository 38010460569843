import React, { useEffect, useRef, useState } from "react";
import { Avatar, Breadcrumb, Button, Card, Carousel, Col, Layout, Popconfirm, Result, Row, Tabs, message , Typography,} from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { _GET, _PATCH, _POST, getRole } from "../../../common/commonFunc";
import Styles from "./FreelancerSearchProfile.module.css";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import axios from "axios";
import { API_BASE_URL, APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import CustomCard from "../../SupplierScreens/Inventory/CustomCard";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { LinkOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import TabPane from "antd/es/tabs/TabPane";
import { AppstoreOutlined, CalendarOutlined, CloseCircleOutlined, ShareAltOutlined, UserOutlined } from "@ant-design/icons";
import HomeLogo from "../../../assests/logoSmall.svg";
import ServiceLogo from "../../../assests/Services.svg";
import EventsLogo from "../../../assests/Event.svg";
import StoreLogo from "../../../assests/Store.svg";
import ProfileLogo from "../../../assests/profileSmall.svg";
import Insta from "../../../assests/InstagramRound.png";
import Navigate from "../../../assests/NavigateRound.png";
import Facebook from "../../../assests/FacebookRound.png";
import Snapchat from "../../../assests/SnapchatRound.png";
import Tiktok from "../../../assests/TiktokRound.png";
import Octopus from "../../../assests/OctopusLike.svg";
import { RWebShare } from "react-web-share";
import GenericPage from "../../SupplierScreens/Profile/GenericPage";


const { Paragraph } = Typography;

const stripHtml = (html: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

const FreelancerSearchProfile = (ids: any) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
   const [freelancerProfile, setFreelancerProfile] = useState<any>({});
   const [activeNav, setActiveNav] = useState("1");
   const [products, setProducts] = useState<any[]>([]);
   const [events, setEventData] = useState<any[]>([]);
   const [eventLoading, setEventLoading] = useState(true);
   const [eventError, setEventError] = useState(false);
   const [productLoading, setProductLoading] = useState(true);
   const [productError, setProductError] = useState(false);
   const [posts, setPosts] = useState<any[]>([]);
   const [isMobile, setIsMobile] = useState(false);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(false);
   const [postsLoading, setPostsLoading] = useState(false);
   const [postsError, setPostsError] = useState(false);
   const [userRole, setUserrole] = useState(getRole());
   const [showHeader, setShowHeader] = useState(true);
   console.log("➡️   ~ FreelancerProfile ~ showHeader:", showHeader);
   const [showBottomNav, setShowBottomNav] = useState(true);
   const lastScrollY = useRef<any>(0);
   console.log("➡️   ~ FreelancerProfile ~ lastScrollY:", lastScrollY);
   const [showDetails, setShowDetails] = useState(true);
   const containerRef = useRef<any>(null);


   const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      const scrollY = container.scrollTop;
      console.log("➡️   ~ handleScroll ~ scrollY:", scrollY);

      if (scrollY === 0) {
        setShowHeader(true);
      } else if (scrollY > lastScrollY.current) {
        setShowHeader(false);
        setShowDetails(false);
      }

      lastScrollY.current = scrollY;
    }
  };

    useEffect(() => {
      const container = containerRef.current;
  
      const addScrollListener = () => {
        if (container) {
          container.addEventListener("scroll", handleScroll);
        }
      };
  
      const removeScrollListener = () => {
        if (container) {
          container.removeEventListener("scroll", handleScroll);
        }
      };
  
      addScrollListener();
      return () => {
        removeScrollListener();
      };
    }, []);
    
    useEffect(() => {
        const container = containerRef.current;
    
        const addScrollListener = () => {
          if (container) {
            container.addEventListener("scroll", handleScroll);
          }
        };
    
        const removeScrollListener = () => {
          if (container) {
            container.removeEventListener("scroll", handleScroll);
          }
        };
    
        addScrollListener();
        return () => {
          removeScrollListener();
        };
      }, []);
      
      useEffect(() => {
        if (containerRef.current) {
          handleScroll();
        }
      }, [containerRef.current]);
    
      useEffect(() => {
        const userrole: any = getRole();
        setUserrole(userrole);
      });
    
      const getData = async () => {
        try {
          const profileData = await _GET(
            `userProfile/freelancerProfile/${id}`,
            true,
            false
          );
          if (profileData.data) {
            setFreelancerProfile(profileData.data);
            setError(false);
            getPosts();
            getProducts();
            getEvents();
          }
        } catch (error) {
          setError(true);
          console.log("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
    
      const getProducts = async () => {
        setProductLoading(true);
        try {
          const productData = await _GET(
            `userProfile/freelancerAffiliatedProducts/${id}`,
            true,
            false
          );
          setProducts(productData.data);
          setProductError(false);
          setProductLoading(false);
        } catch (error) {
          console.log("Error fetching products:", error);
          setProductError(true);
        } finally {
          setProductLoading(false);
        }
      };
    
      const getEvents = async () => {
        setEventLoading(true);
        try {
          const res = await _GET(`userProfile/getFreelancerEvents`, true, false);
          if (res.data.length === 0) return setEventData([]);
          setEventData(res.data);
          setEventError(false);
          setEventLoading(false);
        } catch (error) {
          console.log("🚀 ~ getEvents ~ error:", error);
          setEventError(true);
        } finally {
          setEventLoading(false);
        }
      };
    
      const getPosts = async () => {
        setPostsLoading(true);
        try {
          const postData = await _GET(`social/user/post?userId=${id}`, true, false);
          setPosts(postData.data);
          setPostsError(false);
        } catch (error) {
          setPostsError(true);
          console.log("Error fetching posts:", error);
        } finally {
          setPostsLoading(false);
        }
      };
    
      useEffect(() => {
        getData();
        const handleResize = () => setIsMobile(window.innerWidth <= 576);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [id]);
    
      const handleFollow = async () => {
        setLoading(true);
        try {
          await _POST(
            "userProfile/follow",
            {
              following_entity_id: freelancerProfile.id,
              following_type: "freelancer",
            },
            true,
            true
          );
          setFreelancerProfile({ ...freelancerProfile, is_following: true });
          message.success("Followed successfully");
        } catch (error) {
          message.error("Failed to follow");
        } finally {
          setLoading(false);
        }
      };
    
      const handleUnfollow = async () => {
        setLoading(true);
        try {
          await _PATCH(`unfollowFollowings/${freelancerProfile.id}`, {}, true);
          setFreelancerProfile({ ...freelancerProfile, is_following: false });
          message.success("Unfollowed successfully");
        } catch (error) {
          message.error("Failed to unfollow");
        } finally {
          setLoading(false);
        }
      };
 
      const getClassName = () => {
        if ((showHeader && showDetails) || (!showHeader && !showDetails)) {
          return "";
        }
        if (showHeader && !showDetails) {
          return Styles .tabContainer;
        }
        return "";
      };
    
    
  const renderCard = (item: any, isService: boolean = false) => {
    const handleCardClick = () => {
      if (isService) {
        history.push(`${APPLICATION_ROUTES.SERVICE}/${item.id}`);
      } else {
        history.push(`${APPLICATION_ROUTES.EVENT_DETAILS}/${item.id}`);
      }
    };

    return (
      <Card
        hoverable
        className={Styles.cardWrapper}
        onClick={handleCardClick}
        cover={
          <div className={Styles.cardImageWrapper}>
            <img
              alt={item.title || item.service_name}
              src={item.image || item.service_images[0]}
              className={Styles.coverImage}
            />
            {isService && (
              <div className={Styles.categoryTag}>
                <span>{item.service_category_main}</span>
              </div>
            )}
          </div>
        }
      >
        <Card.Meta
          title={
            <div className={Styles.serviceTitle}>
              <span className={Styles.serviceName}>
                {item.service_name || item.title}
              </span>
              {isService && (
                <span className={Styles.servicePrice}>
                  {item.allowed_quotes ? (
                    "Get a quote"
                  ) : (
                    <span>
                      <span className={Styles.currency}>BHD</span>{" "}
                      <strong>{item.service_fees}</strong>
                    </span>
                  )}
                </span>
              )}
            </div>
          }
          description={
            <div className={Styles.cardContent}>
              <div className={`${Styles.serviceDesc} ${Styles.truncate}`}>
                {stripHtml(item.service_desc || item.description)}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

  const renderProductCard = (product: any) => {
    const handleCardClick = () => {
      history.push(
        `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.product_id}`
      );
    };

    return (
      <Card
        hoverable
        className={Styles.cardWrapper}
        onClick={handleCardClick}
        cover={
          <div className={Styles.cardImageWrapper}>
            <img
              alt={product.product_name}
              src={product.prod_images[0]}
              className={Styles.coverImage}
            />
          </div>
        }
      >
        <Card.Meta
          title={
            <div className={Styles.serviceTitle}>
              <span className={Styles.serviceName}>{product.product_name}</span>
              <span className={Styles.servicePrice}>
                <span className={Styles.currency}>BHD</span>{" "}
                <strong>{product.prod_unit_price}</strong>
              </span>
            </div>
          }
          description={
            <div className={Styles.cardContent}>
              <div className={`${Styles.serviceDesc} ${Styles.truncate}`}>
                {product.prod_category} - {product.prod_sub_category}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

  const renderPostCard = (post: any) => {
    const renderMedia = () => {
      return (
        <Carousel>
          {post.post.imageUrls.map((url: string, index: number) => {
            const isVideo =
              url.endsWith(".mp4") ||
              url.endsWith(".webm") ||
              url.endsWith(".ogg");
            return (
              <div key={index}>
                {isVideo ? (
                  <video controls className={Styles.coverImage}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    alt={post.post.caption}
                    src={url}
                    className={Styles.coverImage}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      );
    };

    return (
      <Card
        hoverable
        className={Styles.cardWrapper}
        cover={<div className={Styles.cardImageWrapper}>{renderMedia()}</div>}
      >
        <Card.Meta
          title={
            <div className={Styles.serviceTitle}>
              <span className={Styles.serviceName}>
                {post.postUserDetails.display_name}
              </span>
            </div>
          }
          description={
            <div className={Styles.cardContent}>
              <div className={`${Styles.serviceDesc} ${Styles.truncate}`}>
                {post.post.caption}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

 
  const socialLinks = [
    {
      name: "Octopus",
      count: freelancerProfile?.octopus_follower_count,
      icon: Octopus,
    },
    {
      name: "Instagram",
      count: freelancerProfile?.insta_follower_count,
      icon: Insta,
    },
    {
      name: "Facebook",
      count: freelancerProfile?.facebook_followers_count,
      icon: Facebook,
    },
    {
      name: "TikTok",
      count: freelancerProfile?.tiktok_followers_count,
      icon: Tiktok,
    },
    {
      name: "Snapchat",
      count: freelancerProfile?.snapchat_followers_count,
      icon: Snapchat,
    },
  ];


  const renderContent = () => {
    switch (activeNav) {
      case "1":
        return (
          <section className={Styles.content}>
            {posts && posts.length > 0 ? (
              posts.map((post: any, i: number) => (
                <div key={i} className={Styles.box}>
                  {renderPostCard(post)}
                  {i === posts.length - 1 && (
                    <div className={Styles.boxLast}>No more posts</div>
                  )}
                </div>
              ))
            ) : (
              <Result
                status="404"
                title="No Posts"
                subTitle="Sorry, there are no posts to display."
                extra={
                  <Button
                    className={Styles.retryButton}
                    type="primary"
                    onClick={getPosts}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "2":
        return (
          <section className={Styles.content}>
            {freelancerProfile?.freelancerServices &&
            freelancerProfile.freelancerServices.length > 0 ? (
              freelancerProfile.freelancerServices.map(
                (service: any, i: number) => (
                  <div key={i} className={Styles.box}>
                    {renderCard(service, true)}
                    {/* {i === freelancerProfile.freelancerServices.length - 1 && (
                      <div className={Styles.boxLast}>No more services</div>
                    )} */}
                  </div>
                )
              )
            ) : (
              <Result
                status="404"
                title="No Services"
                subTitle="Sorry, there are no services to display."
                extra={
                  <Button
                    className={Styles.retryButton}
                    type="primary"
                    onClick={getData}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "3":
        return (
          <section className={Styles.content}>
            {events && events.length > 0 ? (
              events.map((event: any, i: number) => (
                <div key={i} className={Styles.box}>
                  {renderCard(event)}
                  {/* {i === events.length - 1 && (
                    <div className={Styles.boxLast}>No more events</div>
                  )} */}
                </div>
              ))
            ) : (
              <Result
                status="404"
                title="No Events"
                subTitle="Sorry, there are no events to display."
                extra={
                  <Button
                    className={Styles.retryButton}
                    type="primary"
                    onClick={getData}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "4":
        return (
          <section className={Styles.content}>
            {products && products.length > 0 ? (
              products.map((product: any, i: number) => (
                <div key={i} className={Styles.box}>
                  {renderProductCard(product)}
                  {/* {i === products.length - 1 && (
                    <div className={Styles.boxLast}>No more products</div>
                  )} */}
                </div>
              ))
            ) : (
              <Result
                status="404"
                title="No Store Items"
                subTitle="Sorry, there are no items in the store."
                extra={
                  <Button
                    className={Styles.retryButton}
                    type="primary"
                    onClick={getData}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "5":
        return (
            <GenericPage
            pageTitle="Links"
            pageDescription="We are working hard to bring new features. Please check back later."
          />
       
        //   <div className={Styles.socialLinkContainer}>
        //     {socialLinks.map((link, index) => (
        //       <div key={index} className={Styles.socialLinkWrapper}>
        //         <div className={Styles.socialLinkBox}>
        //           <div className={Styles.socialLinkContent}>
        //             <div className={Styles.socialLinkImage}>
        //               <img
        //                 className={Styles.socialLinkIcon}
        //                 src={link.icon}
        //                 alt={link.name}
        //               />
        //             </div>
        //             <div className={Styles.followerInfo}>
        //               <span className={Styles.followerCount}>{link.count}</span>
        //             </div>
        //             <div className={Styles.linkButton}>
        //               <img
        //                 className={Styles.socialLinkIcon}
        //                 src={Navigate}
        //                 alt="go"
        //               />
        //             </div>
        //           </div>
        //         </div>
        //         <br />
        //       </div>
        //     ))}
        //   </div>
        );
      default:
        return null;
    }
  };

  const renderBottomNav = () => {
    const getActiveStatus = (path: any) => {
      return history.location.pathname === path
        ? "grayscale(0%)"
        : "grayscale(100%)";
    };

    const handleProfileClick = () => {
      if (userRole !== "guest") {
        history.push("/profile");
      } else {
        history.push("/login");
      }
    };

    return (
      <div className={Styles.bottomNav}>
        <div
          className={Styles.navItem}
          onClick={() => {
            setActiveNav("/service");
            history.push("/service");
          }}
          style={{
            filter:
              activeNav === "/service" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={ServiceLogo} alt="Services" />
          <span>Services</span>
        </div>
        <div
          className={Styles.navItem}
          onClick={() => {
            setActiveNav("/events");
            history.push("/events");
          }}
          style={{
            filter:
              activeNav === "/events" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={EventsLogo} alt="Events" />
          <span>Events</span>
        </div>
        <div
          className={`${Styles.navItem}`}
          onClick={() => {
            setActiveNav("/");
            history.push("/");
          }}
          style={{
            filter: activeNav === "/" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={HomeLogo} alt="Home" />
          <span>Home</span>
        </div>
        <div
          className={Styles.navItem}
          onClick={() => {
            setActiveNav("/store");
            history.push("/store");
          }}
          style={{
            filter:
              activeNav === "/store" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={StoreLogo} alt="Store" />
          <span>Store</span>
        </div>
        <div
          className={Styles.navItem}
          onClick={handleProfileClick}
          style={{
            filter:
              activeNav === "/profile" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img
            src={ProfileLogo}
            alt="Profile"
            style={{
              border: "1px solid #999999 !important",
              height: "21px",
              width: "21px",
              borderRadius: "2px",
              backgroundColor: "#999999",
            }}
          />
          <span>Profile</span>
        </div>
      </div>
    );
  };
    
   const profileLink = `${window.location.origin}${APPLICATION_ROUTES.PROFILE}/${freelancerProfile.id}`;
  const shareText = `
  🌟 *Discover an Amazing Profile on Octopus!* 🌟\n\n
  👤 *Name:* ${freelancerProfile.display_name}\n
  📝 *Bio:* ${freelancerProfile.user_bio}\n
  📈 *Followers:* ${freelancerProfile.octopus_follower_count}\n\n
  🔗 *Check out their profile here:* ${profileLink}}\n\n
  ✨ Join Octopus to connect with more amazing individuals and explore unique services!\n
  📸
    `;

    

  return (
    <div className={Styles.customLayoutSideContainer}>
          <DashboardNavBar />
   
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header_}>
        <h1>
          <span className={Styles.countingSpanParent}>
      
              Freelancer
           
            Details
          </span>
        </h1>
        
       
      </div>

      <div className={Styles.header}>
                <Avatar
                  size={isMobile ? 50 : 100}
                  src={freelancerProfile.profile_pic}
                  icon={<UserOutlined />}
                  className={isMobile ? Styles.avatar : ""}
                />
                <div className={Styles.profileInfo}>
                  <div className={Styles.nameContainer}>
                    <span className={Styles.displayName}>
                      {freelancerProfile.display_name}
                    </span>
                  </div>
                  <div className={Styles.stats}>
                    <span>{posts.length} Posts</span>
                    <span>{freelancerProfile.totalFollowers} Followers</span>
                  </div>
                  <Paragraph ellipsis={{ rows: 2, expandable: false }}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: freelancerProfile.user_bio,
                      }}
                    />
                  </Paragraph>
                  
                </div>
                <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}>
      
              <Button className={Styles.buttonFilled}
              onClick={
                userRole !== "guest"
                  ? freelancerProfile.is_cus_following_freelancer
                    ? handleUnfollow
                    : handleFollow
                  : () =>
                      message.info(
                        "Guests cannot follow users. Please log in."
                      )
              }
              type="primary"
            >
                  {freelancerProfile.is_cus_following_freelancer
                    ? "Unfollow"
                    : "Follow"}
              
              </Button>
              <RWebShare
                  data={{
                    text: shareText,
                    url: profileLink,
                    title: "Freelancer Profile",
                  }}
                >
                  <Button
                    className={Styles.buttonTransparent}
                    icon={<ShareAltOutlined />}
                  >
                    Share
                  </Button>
                </RWebShare>
          </div>
        </div>
              </div>





      <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className={Styles.parentContentdiv}
      >
        <div style={{ alignItems: "center", justifyContent: "center"}}>
          
            <Tabs
        activeKey={activeNav}
        onChange={(key) => setActiveNav(key)}
        className={`${getClassName()}  ${Styles.centerTabs}`}
      >
        <TabPane
          tab={
            <span style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"16px"}}>
              <AppstoreOutlined className={Styles.tableIcon} />
              Posts
            </span>
          }
          key="1"
          style={{ width:"170px"}}
        />
        <TabPane
          tab={
            <span style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"16px"}}> 
              <AppstoreOutlined className={Styles.tableIcon} />
              Services
            </span>
          }
          key="2"
        />
        <TabPane
          tab={
            <span style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"16px"}} >
              <CalendarOutlined className={Styles.tableIcon} />
              Events
            </span>
          }
          key="3"
        />
        <TabPane
          tab={
            <span style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"16px"}}>
              <ShoppingCartOutlined className={Styles.tableIcon} />
              Store
            </span>
          }
          key="4"
        />
        <TabPane
          tab={
            <span style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"16px"}}>
              <LinkOutlined className={Styles.tableIcon} />
              Links
            </span>
          }
          style={{alignItems:"center"}}
          key="5"
        />
      </Tabs>

        </div>
        <main
        className={showHeader ? Styles.main : Styles.mainHide}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <section className={Styles.content}>{renderContent()}</section>
      </main>
        {/* <CollaborationDetailsTable headers={headers} data={collabDetails} /> */}
      </div>
    </Layout>
    </div>
  );
};


export default FreelancerSearchProfile;
