import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Drawer,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Star,
  Close,
  Inventory,
  Store,
  Business,
  Bookmark,
  BookmarkRemove,
  BookmarkBorderOutlined
} from "@mui/icons-material";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { _POST } from "../../common/commonFunc";

interface Product {
  id: string;
  name: string;
  image: string;
  price: number;
  rating: number;
}

interface Brand {
  id: string;
  name: string;
  logo: string;
}

interface Store {
  id: string;
  name: string;
  image: string;
}

interface Supplier {
  id: string;
  name: string;
  image: string;
  rating: number;
  totalCollaborations: number;
  totalProducts: number;
  totalBrands: number;
  products: Product[];
  brands: Brand[];
  stores: Store[];
}

interface SupplierDrawerProps {
  open: boolean;
  onClose: () => void;
  supplier: Supplier;
}

const SupplierDrawer: React.FC<SupplierDrawerProps> = ({
  open,
  onClose,
  supplier,
}) => {
  console.log("Supplier info drawer data is", supplier);
  const [activeTab, setActiveTab] = useState(0);
  const [savedItem, setSavedItem] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const navigate = useHistory();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleImageClick = () => {
    setImagePreviewOpen(true);
  };

  const handleImageClose = () => {
    setImagePreviewOpen(false);
  };

  const handleSavedItems = async () => {
    const payload = {
      itemType: "profile",
      itemId: supplier?.id
    };

    try {
      const postData = await _POST("userProfile/saveItem", payload, true);
      setSavedItem(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isSmallScreen ? "85vw" : "500px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: 4,
            overflow: "hidden",
            border: "1px solid #E0E0E0",
          },
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            bgcolor: "white",
            boxShadow: 2,
            borderRadius: "50%",
            zIndex: 10,
          }}
        >
          <Close />
        </IconButton>
        <IconButton
          onClick={(event) => {
            handleSavedItems();
            event.preventDefault();
          }}
          sx={{
            position: "absolute",
            top: 16,
            right: 65,
            bgcolor: "white",
            boxShadow: 2,
            borderRadius: "50%",
            zIndex: 10,
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
         {/* {savedItem ? <Bookmark /> ?<BookmarkRemove /> }  */}
          {savedItem ? <Bookmark /> : <BookmarkBorderOutlined />}

        </IconButton>

        <Box
          sx={{
            zIndex: 9,
            pt: 6,
            pb: 3,
            px: 3,
            bgcolor: "#F9FAFB",
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar
              src={supplier.image}
              alt={supplier.name}
              sx={{
                width: 64,
                height: 64,
                cursor: "pointer",
              }}
              onClick={handleImageClick}
            />
            <Box>
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {supplier.name}
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <Typography variant="body2" color="#9CA3AF" mr={1}>
                  Supplier Profile |
                </Typography>
                <Box display="flex" alignItems="center">
                  <Star sx={{ fontSize: 16, color: "#FFD700", mr: 0.5 }} />
                  <Typography variant="body2" fontWeight="bold" color="#31263e">
                    {supplier.rating.toFixed(1)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box textAlign="center">
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {supplier.products?.length}
              </Typography>
              <Typography variant="body2" color="#9CA3AF">
                Products
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {supplier.totalCollaborations}
              </Typography>
              <Typography variant="body2" color="#9CA3AF">
                Collaborations
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {supplier.totalBrands}
              </Typography>
              <Typography variant="body2" color="#9CA3AF">
                Brands
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                color: "#9CA3AF",
                minHeight: "36px",
              },
              "& .Mui-selected": {
                color: "#4F46E5 !important",
                fontWeight: "bold",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#4F46E5",
              },
            }}
          >
            <Tab icon={<Inventory />} label="Products" />
            <Tab icon={<Business />} label="Brands" />
            <Tab icon={<Store />} label="Stores" />
          </Tabs>

          <Box flex={1} overflow="auto" p={3}>
            {activeTab === 0 && (
              <Grid container spacing={3}>
                {supplier.products.map((product) => (
                  <Grid item xs={6} key={product.id}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        boxShadow: 1,
                        transition: "box-shadow 0.3s",
                        "&:hover": { boxShadow: 4 },
                      }}
                    >
                      <Box
                        position="relative"
                        sx={{ borderBottom: "1px solid #E0E0E0" }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={product.image}
                          alt={product.name}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            bgcolor: "#31263e",
                            color: "white",
                            fontSize: "12px",
                            px: 1.5,
                            py: 0.5,
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: 1,
                          }}
                        >
                          <Star sx={{ fontSize: 14, mr: 0.5 }} />
                          {product.rating.toFixed(1)}
                        </Box>
                      </Box>
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          noWrap
                          title={product.name}
                          color="#31263e"
                        >
                          {product.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          noWrap
                        >
                          Product description
                        </Typography>
                      </CardContent>
                      <Box
                        p={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            fontWeight="bold"
                          >
                            BHD
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            color="#31263e"
                          >
                            {product.price.toFixed(3)}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{
                            borderRadius: "16px",
                            textTransform: "none",
                            fontWeight: "bold",
                            px: 2,
                            maxHeight: "30px",
                            bgcolor: "#4F46E5",
                            "&:hover": { bgcolor: "#4F46E5" },
                          }}
                          onClick={() => {
                            navigate.push(
                              `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.id}`
                            );
                          }}
                        >
                          Buy
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            {activeTab === 1 && (
              <Grid container spacing={3}>
                {supplier.brands.map((brand) => (
                  <Grid item xs={6} key={brand.id}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        boxShadow: 1,
                        transition: "box-shadow 0.3s",
                        "&:hover": { boxShadow: 4 },
                      }}
                    >
                      <Box
                        position="relative"
                        sx={{ borderBottom: "1px solid #E0E0E0" }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={brand.logo}
                          alt={brand.name}
                          sx={{ objectFit: "contain", p: 2 }}
                        />
                      </Box>
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          noWrap
                          title={brand.name}
                          color="#31263e"
                        >
                          {brand.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            {activeTab === 2 && (
              <Grid container spacing={3}>
                {supplier.stores.map((store) => (
                  <Grid item xs={6} key={store.id}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        boxShadow: 1,
                        transition: "box-shadow 0.3s",
                        "&:hover": { boxShadow: 4 },
                      }}
                    >
                      <Box
                        position="relative"
                        sx={{ borderBottom: "1px solid #E0E0E0" }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={store.image}
                          alt={store.name}
                        />
                      </Box>
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          noWrap
                          title={store.name}
                          color="#31263e"
                        >
                          {store.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Drawer>

      <Dialog
        open={imagePreviewOpen}
        onClose={handleImageClose}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            position: "relative",
            overflow: "hidden",
          },
        }}
      >
        <IconButton
          onClick={handleImageClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            bgcolor: "white",
            boxShadow: 2,
            zIndex: 1000,
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <Close />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <img
            src={supplier.image}
            alt={supplier.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default SupplierDrawer;
