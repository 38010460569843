import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Switch,
  Route,
  BrowserRouter,
  useLocation,
  useHistory,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import EmailVerify from "./pages/EmailVerify/EmailVerify";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import HomeRoleCategories from "./pages/HomeRoleCategories/HomeRoleCategories";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import VerifyOtp from "./pages/VerifyOtp/VerifyOtp";
import VerifyDocuments from "./pages/Freelancer/VerifyDocuments/VerifyDocuments";
import Categories from "./pages/Freelancer/Categories/Categories";
import { APPLICATION_ROUTES } from "./constant/ApplicationRoutes";
import axios from "axios";
import OtherDetails from "./pages/OtherDetails/OtherDetais";
import GlobalStylesConfig from "./theme/GlobalStyles";
import SupplierForm from "./pages/SupplierStepperAnt/MainFormSupplierUpdated";
import { AuthProvider } from "./context/authContext";
import SupplierRegister from "./pages/SupplierStepperAnt/SupplierRegister";
import Events from "./pages/Events/Service/Service";
import LoadingIndicator from "./loadingind";
import Social from "./assests/socialMediaImage.svg";
import theme from "./theme/theme";
import EKYC from "./pages/EKYC/EKYC";
import DashboardPage from "./pages/EndUser/DashboardPage/DashboardPage";
import Service from "./pages/ServiceProvioder/Service/Service";
import { CometChat } from "@cometchat/chat-sdk-javascript"; //import sdk package
import { CometChatUIKitConstants } from "@cometchat/uikit-resources";
import Map from "./pages/Map/Map";
import ServiceConfirmationPage from "./pages/ServiceProvioder/ServiceConfirmation/ServiceConfirmation";
import Calendar from "./pages/ServiceProvioder/Order/Calendar";
import BookingUiNew from "./pages/ServiceProvioder/ServiceBooking/BookingUiNew";
import ProfilePage from "./pages/Profile/Profile";
import DrawerMessage from "./assests/FreelancerMessage.png";
import DrawerSaved from "./assests/FreelancerSaved.png";
import DrawerLocation from "./assests/FreelancerLocation.png";
// import EventConfirmationPage from "./pages/ServiceProvioder/ServiceConfirmation/ServiceConfirmation";
import EventConfirmationPage from "./pages/Events/ServiceConfirmation/ServiceConfirmation";
import ServicesDetails from "./pages/ServiceProvioder/ServiceDetails/ServiceDetailsNew";
import PersonalInfo from "./pages/Profile/PersonalInfo/PersonalInfo";
import { SavedServices } from "./pages/Profile/SavedPages/SavedServices";
import Preferences from "./pages/Profile/Preferences/Preferences";
import { PrivacyPolicy } from "./pages/Profile/PrivacyPolicy/PrivacyPolicy";
import { TermsConditions } from "./pages/Profile/TermsAndConditions/TermsConditions";
import ChangePasswordDrawer from "./pages/Profile/ChangePaswordOption/ChangePasswordDrawer";
import EventDetailsNew from "./pages/Events/ServiceDetails/ServiceDetailNew";
import { BankInfo } from "./pages/Profile/BankInformation/BankInfo";
import SupplierHome from "./pages/SupplierScreens/SupplierHome";
import { MyDocuments } from "./pages/Profile/MyDocuments/MyDocuments";
import { MySchedule } from "./pages/Profile/MySchedule/MySchedule";
import ProfileAddresses from "./pages/Profile/Addresses/Addresses";
import { PaymentMethod } from "./pages/Profile/PaymentMethods/PaymentMethod";
import _ from "lodash";
import { SearchProvider } from "./common/DashboardNavBar/Search/SearchContext";
import { _GET, _POST, getUserId } from "./common/commonFunc";
import StoreHomePage from "./pages/Store/StorePage/Store";
import { ManageSocialMedia } from "./pages/Profile/ManageSocialMedia/ManageSocialMedia";
import MyDashboard from "./pages/ServiceProvioder/MyDashboard/MyDashboard";
import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from "@cometchat/chat-uikit-react";
import SupplierRegistrationForm from "./pages/SupplierRegister/SupplierRegistrationForm";
import CustomerOrderPage from "./pages/OrderPages/CustomerPage/CustomerorderPage";
import FreelancerOrderPage from "./pages/OrderPages/SupplierPage/FreelancerOrderPage";
import BankAccountForm from "./pages/Freelancer/BankAccountDetails/BankDetailsNew";
import ProductBooking from "./pages/Store/Store/ProductBooking";
import ProductDetails from "./pages/Store/Store/ProductDetailsCustomer";
import { Blogs } from "./pages/Profile/Blogs/Blogs";
import AffiliateProducts from "./pages/Profile/AffiliateProducts/AffiliateProducts";
import { HelpSupport } from "./pages/Profile/HelpSupport/HelpSupport";
import { Coupons } from "./pages/Profile/Coupons/Coupons";
import { ManageNotification } from "./pages/Profile/ManageNotifications/ManageNotification";
import ChatLogin from "./pages/chats/ChatLogin";
import { FreelancerDrawer } from "./common/FreelancerDrawer/FreelancerDrawer";
import ChatConversations from "./pages/chats/ChatConversations";

import ProductList from "./pages/SupplierScreens/Store/Productlist";
import Productdetails from "./pages/SupplierScreens/Store/Productdetails";
import Orderlisting from "./pages/SupplierScreens/ManageOrders/Orderlisting";
import ProductConfirmation from "./pages/Store/Store/ProductConfirmationPage";
import InventoryDetails from "./pages/SupplierScreens/Inventory/InventoryDetails";
import ChatConversationsSupplier from "./pages/chats/ChatConversationSuppplier";
import ChatSupplierLoginHome from "./pages/chats/ChatSupplierConversationHome";
import MultipleProductBooking from "./pages/Store/Store/MultipleProductBooking";
import { MyPost } from "./pages/Profile/Posts.tsx/MyPost";
import InstagramfetchFollowers from "./pages/Instagram/InstagramfetchFollowers";
import FreelancerDashboard from "./pages/ServiceProvioder/FreelancerDashboard/FreelancerDashboard";
import { SupplierDrawer } from "./common/SupplierDrawer/SupplierDrawer";
import ServiceBookingByQuote from "./pages/ServiceProvioder/ServiceBooking/ServiceBookingByQuote";
import ServiceQuoteConfirmationPage from "./pages/ServiceProvioder/ServiceConfirmation/ServiceQuoteConfirmation";
import ProfileEKYC from "./pages/EKYC/ProfileEKYC";
import VerifyDocumentsProfile from "./pages/Freelancer/VerifyDocuments/ProfileDocsUpdate";
import CollaboartionPaymentConfirmationPage from "./pages/SupplierScreens/Collabration/CollaborationPaymentConfirmation/CollaborationPaymentConfirmation";
import Collab from "./pages/SupplierScreens/Collabration/SupplierCollab";
import KYCVerificationResult from "./pages/EKYC/EKYCRES";
import { HelmetProvider } from "react-helmet-async";
import GeneratePasswordShareholder from "./pages/Login/ShareHolderPassword";
import FreelancerCollaborations from "./pages/Profile/AffiliateProducts/FreelancerCollaborations";
import FreeLancerCollabDetails from "./pages/Profile/AffiliateProducts/FreelancerCollabDetails";
import SearchPage from "./common/DashboardNavBar/Search/SearchPage";
import ConferenceCall from "./pages/chats/ConferenceCall";
import ProtectedRoute from "./components/ProtectedRoute";
import { Roles } from "./utils/rolesConfig";
import Unauthorized from "./pages/unoth/unoth";
import { getToken } from "firebase/messaging";
import { onMessageListener, requestFCMToken } from "./firebase";
import { notification as notificationTrigger } from "antd";
import FreelancerSearchProfile from "./pages/ServiceProvioder/ServiceDetails/FreelancerSearchProfile";

function App() {
  const disabledLoggingHosts = [
    "api.theoctopus.ai",
  ];

  const currentHostname = window.location.hostname.replace(/:\d+$/, "");

  if (disabledLoggingHosts.includes(currentHostname)) {
    const noop = () => {};
    const consoleMethods = [
      "log",
      "warn",
      "error",
      "info",
      "debug",
      "trace",
      "group",
      "groupEnd",
      "groupCollapsed",
    ] as const;
    consoleMethods.forEach((method) => {
      (console as any)[method] = noop;
    });
  }
  const COMETCHAT_CONSTANTS = {
    APP_ID: "258835f9d7b5c927",
    REGION: "EU",
    AUTH_KEY: "8835837b4af0af1335a8277f37a9c38ab381a008",
  };

  try {
    useEffect(() => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (token) {
          axios.defaults.headers.common["Authorization"] = token;
        }
        const UIKitSettings = new UIKitSettingsBuilder()
          .setAppId(COMETCHAT_CONSTANTS.APP_ID)
          .setRegion(COMETCHAT_CONSTANTS.REGION)
          .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
          .subscribePresenceForFriends()
          .build();
        if (true) {
          // @ts-ignore
          CometChatUIKit.init(UIKitSettings).then((res: any) => {
            console.log("res", res);
            console.log("Initialization completed successfully");
            const { id } = JSON.parse(localStorage.getItem("userInfo") || "{}");
            CometChatUIKit.login(id)
              .then((user) => {
                console.log("Login Successful:", { user });
                if (!user) {
                }
              })
              .catch(async (err: any) => {
                console.log("Chat not available right now : ", err);
              });
          });
        }
      } catch (error) {}
    }, []);

    axios.interceptors.request.use(
      function (config) {
        const loadingIndicator = document.getElementById("loading-indicator");

        if (window.location.pathname !== "/search" && loadingIndicator) {
          loadingIndicator.style.display = "flex";
        }
        return config;
      },
      function (error) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "none";
        }
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "none";
        }
        return response;
      },
      function (error) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "none";
        }
        return Promise.reject(error);
      }
    );
  } catch (error) {
    document.body.classList.remove("loading-indicator");
  }

  const observerErrorHandler = (err: any) => {
    if (
      err.message ===
      "ResizeObserver loop completed with undelivered notifications."
    ) {
      return;
    }
    console.error(err);
  };

  window.addEventListener("error", observerErrorHandler);
  console.log("Application Route Config - SERVICE_BOOKING:", {
    allowedRoles: [Roles.USER, Roles.BASICUSER],
  });

  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  console.log("🚀 ~ App ~ notifications:", notifications);

  const [notificationQueue, setNotificationQueue] = useState<any[]>([]);

  useEffect(() => {
    const setupForegroundNotificationListener = async () => {
      console.log("Setting up foreground notification listener...");
      onMessageListener()
        .then((payload) => {
          console.log(
            "Foreground Notification Payload Received in App.tsx:",
            payload
          );
          const { notification } = payload;

          if (notification) {
            const newNotification = {
              title: notification?.title,
              body: notification?.body,
              image: notification?.image,
            };

            console.log("Adding to notificationQueue:", newNotification);
            setNotificationQueue((prevQueue) => [
              ...prevQueue,
              newNotification,
            ]);
          } else {
            console.warn(
              "Notification payload is missing 'notification' object"
            );
          }
        })
        .catch((error) => {
          console.error("Error in foreground notification listener:", error);
        });
    };

    setupForegroundNotificationListener();
  }, []);

  // useEffect(() => {
  //   if (notificationQueue.length > 0) {
  //     const [nextNotification, ...remainingQueue] = notificationQueue;

  //     notificationTrigger.open({
  //       message: nextNotification.title,
  //       description: (
  //         <div>
  //           <p>{nextNotification.body}</p>
  //           {nextNotification.image && (
  //             <img
  //               src={nextNotification.image}
  //               alt="Notification"
  //               style={{ width: "100%", maxWidth: "300px", marginTop: "10px" }}
  //             />
  //           )}
  //         </div>
  //       ),
  //       placement: "topRight",
  //       duration: 5,
  //       onClose: () => {
  //         setNotificationQueue(remainingQueue);
  //       },
  //     });
  //   }
  // }, []);

  return (
    <>
      <HelmetProvider>
        <LoadingIndicator />
        <BrowserRouter>
          <SearchProvider>
            <AuthProvider>
              <GlobalStylesConfig />
              <Switch>
                {/* Common routes */}
                <Route
                  exact
                  path={APPLICATION_ROUTES.HOME}
                  render={() => <HomeRoleCategories />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.LOGIN}
                  render={() => <Login />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.LOGIN_HOME}
                  render={() => <Login />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.REGISTER}
                  render={() => <Register />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.EMAIL_VERIFY}
                  render={() => <EmailVerify />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.FORGOT_PASSWORD}
                  render={() => <ForgotPassword />}
                />
                <Route
                  exact
                  path={`${APPLICATION_ROUTES.RESET_PASSWORD}/:token`}
                  render={() => <ResetPassword />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.VERIFY_OTP}
                  render={() => <VerifyOtp />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.INSTA}
                  render={() => <InstagramfetchFollowers />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.BANK_DETAILS}
                  render={() => <BankAccountForm />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.CATEGORIES}
                  render={() => <Categories />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.VERIFY_DOCUMENTS}
                  render={() => <VerifyDocuments />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.VerifyDocumentsProfile}
                  render={() => <VerifyDocumentsProfile />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.MAP}
                  render={() => <Map />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.E_KYC}
                  render={() => <EKYC />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.EKYCVerifyResult}
                  render={() => <KYCVerificationResult />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.ProfileEKYC}
                  render={() => <ProfileEKYC />}
                />
                <Route
                  exact
                  path={APPLICATION_ROUTES.OTHER_DETAILS}
                  render={() => <OtherDetails />}
                />
                <Route
                  exact
                  path={`${APPLICATION_ROUTES.SHAREHOLDER_SIGNUP}/:token`}
                  render={() => <GeneratePasswordShareholder />}
                />

                {/* Common protected routes */}

                <ProtectedRoute
                  exact
                  path={`${APPLICATION_ROUTES.PROFILE}/:id`}
                  component={FreelancerSearchProfile}
                  allowedRoles={[
                    Roles.USER,
                    Roles.BASICUSER,
                    Roles.FREELANCER,
                    Roles.GUEST,
                    Roles.SUPPLIER,
                  ]}
                />
                <ProtectedRoute
                  exact
                  path={APPLICATION_ROUTES.DASHBOARD}
                  component={DashboardPage}
                  allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={APPLICATION_ROUTES.SERVICE}
                  component={Service}
                  allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={APPLICATION_ROUTES.EVENTS}
                  component={Events}
                  allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={`${APPLICATION_ROUTES.SERVICE}/:serviceId`}
                  component={ServicesDetails}
                  allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={`/ConferenceCall/:sessionID?`}
                  component={ConferenceCall}
                  allowedRoles={[Roles.USER,Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={`${APPLICATION_ROUTES.EVENT_DETAILS}/:serviceId`}
                  component={EventDetailsNew}
                  allowedRoles={[Roles.USER,Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={`${APPLICATION_ROUTES.PRODUCT_DETAILS}/:serviceId`}
                  component={ProductDetails}
                  allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={APPLICATION_ROUTES.CALENDAR}
                  component={Calendar}
                  allowedRoles={[Roles.USER,Roles.BASICUSER, Roles.FREELANCER]}
                />
                <ProtectedRoute
                  exact
                  path={APPLICATION_ROUTES.STORE}
                  component={StoreHomePage}
                  allowedRoles={[Roles.USER,Roles.BASICUSER, Roles.GUEST]}
                />
                <ProtectedRoute
                  exact
                  path={APPLICATION_ROUTES.ORDER}
                  component={CustomerOrderPage}
                  allowedRoles={[Roles.USER, Roles.BASICUSER]}
                />

                {/* Theme Routes */}
                <ThemeProvider theme={theme}>
                  {/* Common Routes */}
                  <Route
                    exact
                    path={APPLICATION_ROUTES.supplier}
                    render={() => <SupplierRegister />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.SUPPLIER_REGISTRATION_OLD}
                    render={() => <SupplierForm />}
                  />
                  <Route exact path={`/search`} render={() => <SearchPage />} />
                  <Route
                    exact
                    path={`${"/newUi/"}:serviceId`}
                    render={() => <ServicesDetails />}
                  />

                  {/* protected routes */}
                  <ProtectedRoute
                    exact
                    path={`${APPLICATION_ROUTES.SERVICE_BOOKING}/:serviceId?`}
                    component={BookingUiNew}
                    allowedRoles={[Roles.USER, Roles.BASICUSER]}
                  />
                  <ProtectedRoute
                    exact
                    path={`${APPLICATION_ROUTES.SERVICE_QUOTE_BOOKING}/:quotedServiceId/:quoteRequestId`}
                    component={ServiceBookingByQuote}
                    allowedRoles={[Roles.USER, Roles.BASICUSER]}
                  />
                  <ProtectedRoute
                    exact
                    path={`${APPLICATION_ROUTES.PRODUCT_BOOKING}/:serviceId?`}
                    component={ProductBooking}
                    allowedRoles={[Roles.USER, Roles.BASICUSER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.MULTI_CHECKOUT}
                    component={MultipleProductBooking}
                    allowedRoles={[Roles.USER, Roles.BASICUSER]}
                  />
                  <ProtectedRoute
                    exact
                    path="/freelancer-dashboard"
                    component={FreelancerDashboard}
                    allowedRoles={[Roles.FREELANCER]}
                  />

                  <Route
                    path={`${APPLICATION_ROUTES.SERVICE_CONFIRMATION}/:booking_id/:charge_id`}
                    exact
                    component={ServiceConfirmationPage}
                  />
                  <Route
                    path={`${APPLICATION_ROUTES.SERVICE_QUOTE_CONFIRMATION}/:booking_id/:charge_id`}
                    exact
                    component={ServiceQuoteConfirmationPage}
                  />
                  <Route
                    path={`${APPLICATION_ROUTES.EVENT_CONFIRMATION}/:event_id/:charge_id`}
                    exact
                    component={EventConfirmationPage}
                  />
                  <Route
                    path={`${APPLICATION_ROUTES.COLLABORATION_PAYMENT_CONFIRMATION}/:collaboration_id/:contract_id/:contract_mode/payment-status`}
                    exact
                    component={CollaboartionPaymentConfirmationPage}
                  />
                  <Route
                    path={`${APPLICATION_ROUTES.PRODUCT_CONFIRMATION}/:order_id/:charge_id`}
                    exact
                    component={ProductConfirmation}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.PROFILE}
                    component={ProfilePage}
                    allowedRoles={[Roles.USER,Roles.BASICUSER, Roles.FREELANCER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.MY_POSTS}
                    component={MyPost}
                    allowedRoles={[Roles.FREELANCER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.PERSONAL_INFO}
                    component={PersonalInfo}
                    allowedRoles={[Roles.USER,Roles.BASICUSER, Roles.FREELANCER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.PREFERENCES}
                    component={Preferences}
                    allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.PROFILE_SAVED_SERVICES}
                    component={SavedServices}
                    allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.TERMS_AND_CONDITIONS}
                    component={TermsConditions}
                    allowedRoles={[Roles.USER, Roles.BASICUSER, Roles.FREELANCER]}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.BLOGS}
                    render={() => <Blogs />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.AFFILIATE_PRODUCTS}
                    render={() => <AffiliateProducts />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.HELP_AND_SUPPORT}
                    render={() => <HelpSupport />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.COUPONS}
                    render={() => <Coupons />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.PRICAY_AND_POLICY}
                    render={() => <PrivacyPolicy />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.MANAGE_NOTIFICATIONS}
                    render={() => <ManageNotification />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.CHANGE_PASSWORD}
                    render={() => <ChangePasswordDrawer />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.BANK_INFORMATION}
                    render={() => <BankInfo />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.MY_DOCUMENTS}
                    render={() => <MyDocuments />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.MY_SCHEDULE}
                    render={() => <MySchedule />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.PROFILE_ADDRESS}
                    render={() => <ProfileAddresses />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.PAYMENT_METHODS}
                    render={() => <PaymentMethod />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.MANAGE_SOCIAL_MEDIA}
                    render={() => <ManageSocialMedia />}
                  />

                  {/* Supplier Routes */}
                  <ProtectedRoute
                    exact
                    path={`${APPLICATION_ROUTES.SUPPLIER_HOME}/:component`}
                    component={SupplierHome}
                    allowedRoles={[Roles.SUPPLIER]}
                  />
                  <ProtectedRoute
                    exact
                    path={`${APPLICATION_ROUTES.SUPPLIER_HOME}/:component/:id`}
                    component={SupplierHome}
                    allowedRoles={[Roles.SUPPLIER]}
                  />

                  {/* Other Routes */}
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.BOOKINGS}
                    component={FreelancerOrderPage}
                    allowedRoles={[Roles.FREELANCER]}
                  />
                  <ProtectedRoute
                    exact
                    path={APPLICATION_ROUTES.COLLABORATIONS}
                    component={FreelancerCollaborations}
                    allowedRoles={[Roles.FREELANCER]}
                  />
                  <Route
                    exact
                    path={`${APPLICATION_ROUTES.FREELANCERCOLLABDETAILS}/:id`}
                    component={FreeLancerCollabDetails}
                  />
                  <Route path="/my-dashboard" render={() => <MyDashboard />} />

                  <Route path="/unauthorized" render={() => <Unauthorized />} />
                  <Route
                    path="/suu"
                    render={() => <SupplierRegistrationForm />}
                  />
                  <Route
                    path={APPLICATION_ROUTES.SUPPLIER_REGISTRATION}
                    render={() => <SupplierRegistrationForm />}
                  />
                  <Route
                    exact
                    path={`${APPLICATION_ROUTES.CHAT_PAGE}/:receiverID`}
                    render={() => <ChatLogin />}
                  />
                  <Route
                    exact
                    path={APPLICATION_ROUTES.CHAT_PAGE}
                    render={() => <ChatConversations />}
                  />
                  <Route path="/suu2" render={() => <BankAccountForm />} />
                  <Route path="/product-list" render={() => <ProductList />} />
                  <Route
                    path={`/supplier/product-details/:id`}
                    render={() => <Productdetails />}
                  />
                  <Route path="/order-list" render={() => <Orderlisting />} />
                </ThemeProvider>
              </Switch>
              <FreelancerDrawer />
              <SupplierDrawer />
            </AuthProvider>
          </SearchProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
