import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import RatingStar from '../../../assests/customIcons/RatingStar';
import LoginPopUp from '../../../pages/Login/LoginPopup';
import BrandDrawer from '../../../components/Drawer/BrandDrawer';
import { getShortenText } from '../../../utils/helpers';
import _ from 'lodash';
import "./TopCard.css";

const TopCardBrand = React.memo(({ topItem, enableClick, userType }: any) => {
    const [brandDrawer, setBrandDrawer] = useState(false);




    const rating = 4.5;

    const openBrandDrawer = () => {
        setBrandDrawer(true);
    }
    const closeBrandDrawer = () => {
        setBrandDrawer(false);
    }


    const avatarLetter = (topItem?.name || "Octopus Brand")
        .charAt(0)
        .toUpperCase();

    return (
        <>
            <div className="topCardContainer" onClick={openBrandDrawer}>
                <div style={{ position: "relative" }} >
                    {topItem?.brand_image && topItem?.brand_image !== null && topItem?.brand_image !== "[]" && !topItem?.brand_image?.startsWith("[") ? (
                        <img className="topImage" onClick={openBrandDrawer} src={topItem?.brand_image} alt="profile" />
                    ) : (
                        <div 
                            className="topImage"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f0f0f0",
                                color: "#555",
                                fontSize: "20px",
                                fontWeight: "bold",
                                borderRadius: "50%",
                            }}
                        >
                            {avatarLetter}
                        </div>
                    )}
                    {rating && (
                        <div className="ratingContainer">
                            <RatingStar />
                            <p className="ratingText">{topItem?.user_rating}</p>
                        </div>
                    )}
                </div>
                <div className="topInfo">
                    <p className="mainTitle" style={{ marginTop: rating ? "15px" : 0 }}>
                        {topItem?.name}
                    </p>
                    {/* {category && <p className="subTitle">{category}</p>}
                    <p className="extraText">
                        {_.get(
                            topItem,
                            userType === "freelancer"
                                ? "octopus_follower_count"
                                : "totalcollaborations",
                            "0"
                        )}
                        &nbsp;{userType === "freelancer" ? "Followers" : "Collaborations"}
                    </p> */}
                </div>
            </div>
            <BrandDrawer
                open={brandDrawer}
                onClose={closeBrandDrawer}
                brand={topItem}
            />
        </>
    );
});

export default TopCardBrand