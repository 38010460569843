import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Drawer,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Star,
  Close,
  Inventory,
  Business,
  Bookmark,
} from "@mui/icons-material";

interface Product {
  id: string;
  name: string;
  image: string;
  price: number;
  rating: number;
}

interface Supplier {
  id: string;
  name: string;
  logo: string;
  location: string;
}

interface Brand {
  id: string;
  name: string;
  logo: string;
  brand_image:string;
  backgroundImage: string;
  foundedYear: string;
  headquarters: string;
  website: string;
  description: string;
  totalProducts: number;
  averageRating: number;
  totalCollaborations: number;
  products: Product[];
  suppliers: Supplier[];
}

interface BrandDrawerProps {
  open: boolean;
  onClose: () => void;
  brand: Brand;
}

const BrandDrawer: React.FC<BrandDrawerProps> = ({ open, onClose, brand }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleImageClick = () => {
    setImagePreviewOpen(true);
  };

  const handleImageClose = () => {
    setImagePreviewOpen(false);
  };

  const avatarLetter = (brand?.name || "Octopus Brand")
  .charAt(0)
  .toUpperCase();
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isSmallScreen ? "85vw" : "500px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: 4,
            overflow: "hidden",
            border: "1px solid #E0E0E0",
          },
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            bgcolor: "white",
            boxShadow: 2,
            borderRadius: "50%",
            zIndex: 10,
          }}
        >
          <Close />
        </IconButton>
        <IconButton
          sx={{
            position: "absolute",
            top: 16,
            right: 65,
            bgcolor: "white",
            boxShadow: 2,
            borderRadius: "50%",
            zIndex: 10,
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <Bookmark />
        </IconButton>

        <Box
          sx={{
            zIndex: 9,
            pt: 6,
            pb: 3,
            px: 3,
            bgcolor: "#F9FAFB",
          }}
        >
          <Box
            sx={{
              height: 0,
              backgroundImage: `url(${brand.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 2,
              mb: 3,
            }}
          />
          <Box display="flex" alignItems="center" gap={2}>
          {brand?.brand_image && brand?.brand_image !== null ? (
                        <img className="topImage" src={brand?.brand_image} alt="profile" />
                    ) : (
                        <div 
                            className="topImage"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f0f0f0",
                                color: "#555",
                                fontSize: "20px",
                                fontWeight: "bold",
                                borderRadius: "50%",
                            }}
                        >
                            {avatarLetter}
                        </div>
                    )}
            <Box>
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {brand?.name}
              </Typography>
              <Typography variant="body2" color="#9CA3AF">
                {/* {brand.headquarters} */}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box textAlign="center">
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {brand?.products?.length}
              </Typography>
              <Typography variant="body2" color="#9CA3AF">
                Products
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="h6" fontWeight="bold" color="#31263e">
                {brand?.averageRating}
              </Typography>
              {/* <Typography variant="body2" color="#9CA3AF">
                Avg. Rating
              </Typography> */}
            </Box>
            <Box textAlign="center">
              {/* <Typography variant="h6" fontWeight="bold" color="#31263e">
                {brand.totalCollaborations}
              </Typography> */}
              {/* <Typography variant="body2" color="#9CA3AF">
                Collaborations
              </Typography> */}
            </Box>
          </Box>
        </Box>

        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                color: "#9CA3AF",
                minHeight: "36px",
              },
              "& .Mui-selected": {
                color: "#4F46E5 !important",
                fontWeight: "bold",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#4F46E5",
              },
            }}
          >
            <Tab icon={<Inventory />} label="Products" />
            {/* <Tab icon={<Business />} label="Suppliers" /> */}
          </Tabs>

          <Box flex={1} overflow="auto" p={3}>
            {activeTab === 0 && (
              <Grid container spacing={3}>
                {brand && brand?.products?.map((product:any) => (
                  <Grid item xs={6} key={product?.product_id}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        boxShadow: 1,
                        transition: "box-shadow 0.3s",
                        "&:hover": { boxShadow: 4 },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={product?.prod_images}
                        alt={product.name}
                      />
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          noWrap
                          title={product?.product_name}
                          color="#31263e"
                        >
                          {product.product_name}
                        </Typography>
                        <Box mt={1}>
                          <Typography
                            variant="caption"
                            fontWeight="bold"
                            color="#9CA3AF"
                          >
                            Price: BHD {product?.prod_unit_price.toFixed(2)}
                          </Typography>
                        </Box>
                        <Box mt={1} display="flex" alignItems="center">
                          <Star
                            sx={{ fontSize: 16, color: "#FFD700", mr: 0.5 }}
                          />
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            color="#31263e"
                          >
                            {product.avg_rating}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            {/* {activeTab === 1 && (
              <Grid container spacing={3}>
                {brand.suppliers.map((supplier) => (
                  <Grid item xs={6} key={supplier.id}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        boxShadow: 1,
                        transition: "box-shadow 0.3s",
                        "&:hover": { boxShadow: 4 },
                      }}
                    >
                      <Box position="relative">
                        <CardMedia
                          component="img"
                          height="140"
                          image={supplier.logo}
                          alt={supplier.name}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            bgcolor: "#31263e",
                            color: "white",
                            fontSize: "12px",
                            px: 1.5,
                            py: 0.5,
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: 1,
                          }}
                        >
                          <Star sx={{ fontSize: 14, mr: 0.5 }} />
                          4.5
                        </Box>
                      </Box>
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          noWrap
                          title={supplier.name}
                          color="#31263e"
                        >
                          {supplier.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          color="#9CA3AF"
                        >
                          {supplier.location}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )} */}
          </Box>
        </Box>
      </Drawer>

      <Dialog
        open={imagePreviewOpen}
        onClose={handleImageClose}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            position: "relative",
            overflow: "hidden",
          },
        }}
      >
        <IconButton
          onClick={handleImageClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            bgcolor: "white",
            boxShadow: 2,
            zIndex: 1000,
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <Close />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <img
            src={brand.backgroundImage}
            alt={brand.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default BrandDrawer;
