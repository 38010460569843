import React from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";

import { Input, Form, Button, Modal, notification } from "antd";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { _POST } from "../../common/commonFunc";
import { MailOutlined } from "@ant-design/icons";
import CustomLayoutModalTick from "../../assests/errorPNG.png";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
type Props = {};

const ForgotPassword = (props: Props) => {
  const history = useHistory();

  const showFailCustomModal = ({
    title,
    smallText,
    buttonText,
    shouldReloadOnClose,
  }: any) => {
    return Modal.error({
      title: "",
      centered: true,
      width: "600px",
      icon: "",
      className: "customLayoutClassNameProp",
      // okText: buttonText,
      footer: (
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              if (shouldReloadOnClose) {
                window.location.reload();
              }
              Modal.destroyAll(); // This will close the modal
            }}
            style={{
              backgroundColor: "#4E4DFF", // Custom color
              color: "white", // Text color
              borderRadius: "20px", // Rounded corners
              fontSize: "16px", // Font size
              fontWeight: "bold", // Bold font
            }}
            className="customLayoutButtonPopup"
          >
            {buttonText}
          </Button>
        </div>
      ),

      content: (
        <div className="customLayoutConatinerTick">
          <img height="90px" width={"90px"} src={CustomLayoutModalTick} />{" "}
          <div className="customLayoutContainerBox">
            <div className="customLayoutTitleText">{title}</div>
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                fontWeight: "bold",
                fontSize: "24px",
                // eye appealing red color
                color: "#4E4DFF",
              }}
              className="customLayoutSmallText"
            >
              {smallText}
            </div>
          </div>
        </div>
      ),
      onOk: () => {
        // Check if shouldReloadOnClose is true and reload the window if so
        if (shouldReloadOnClose) {
          window.location.reload();
        }
      },

      onCancel: () => {
        // Check if shouldReloadOnClose is true and reload the window if so
        if (shouldReloadOnClose) {
          window.location.reload();
        }
      },
    });
  };

  const onForgotPsdHandler = async ({ email }: any) => {
    localStorage.setItem("userEmail", email);
    const response = await _POST(
      "forgotPassword/sendLink",
      {
        email,
      },
      false,
      false
    );
    if (response && response.data && response.status) {
      // notification.open({
      //   type: "success",
      //   message: "We sent an link to your E-mail",
      //   description: "Click on that link and reset your password",
      // });
      showSuccessCustomModal({
        title: "We sent an link to your E-mail",
        smallText:
          "Click on the link we sent you in your email to reset your password.",
        buttonText: "Continue",
      });
      history.push(APPLICATION_ROUTES.LOGIN);
    } else {
      // notification.open({
      //   type: "error",
      //   message: "Unable to send link",
      //   description: response.message,
      // });

      showFailCustomModal({
        title: "Unable to send link",
        smallText: response.message,
        buttonText: "Continue",
      });
    }
  };
  return (
    <CustomLayout
      heading1="Forgot Password"
      heading2="Please enter your register email address, we’ll send you reset password link to your mail."
    >
      <div className="registerFormMainContainer">
        <Form
          name="basic"
          size="large"
          onFinish={onForgotPsdHandler}
          style={{ margin: "8%", width: "70%" }}
        >
          <Form.Item
            // label='Username'
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              prefix={<MailOutlined className="preFixAntdClass" />}
              placeholder="Email"
              style={{ height: "40px", borderRadius: "67px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
              style={{ width: "100%" }}
              // onClick={() => history.push("/reset-password")}
              // loading={loginLoading}
            >
              Send Verification Link
            </Button>
          </Form.Item>
          {/* <Divider plain>Or Continue With</Divider>
              <div className="flex">
                <img onClick={() => googleLogin()} src={googleLogo} alt="" />
              </div> */}
          <div className="otherLoginSection">
            <div className="setFlexCol setTextStart ">
              <span>You dont have account ?</span>
              <Button
                htmlType="button"
                onClick={() => history.push(APPLICATION_ROUTES.HOME)}
                style={{ color: "black", fontWeight: "bold", padding: 0 }}
                type="link"
              >
                Register
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </CustomLayout>
  );
};

export default ForgotPassword;
