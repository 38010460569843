import React, { useEffect, useState } from "react";
import Styles from "./ServiceProviderCard.module.css";
import {
  followUser,
  getFreelancerProfile,
} from "../../../../redux/actions/FreelancerProfile";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { getRole } from "../../../../common/commonFunc";
import { Typography } from "@mui/material";


const ServiceProviderCard = ({ serviceProviderDetails, providerID }: any) => {
  console.log(
    "➡️   ~ ServiceProviderCard ~ serviceProviderDetails:",
    serviceProviderDetails
  );
  const [openDrawer, setopenDrawer] = React.useState<any>(false);
  const dispatch = useDispatch<any>();
  const displayName = serviceProviderDetails?.display_name
  ? serviceProviderDetails?.display_name
  : `${serviceProviderDetails?.first_name} ${serviceProviderDetails?.last_name}`;
const initial = serviceProviderDetails?.first_name?.charAt(0);
  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(providerID, (value: any) => {
        setopenDrawer(value);
      })
    );
  };
  const followFunctionHandler = async () => {
    await dispatch(followUser(providerID, "freelancer"));
    await dispatch(getFreelancerProfile(providerID, () => {}));
  };
  return (
    <>
    <div className={Styles.cardContainer}>
      <div className={Styles.profileSection}>
        <div
          onClick={onFreelancerProfileClickHandler}
          className={Styles.profileImage}
        >
          {serviceProviderDetails?.profile_pic !== null &&
          serviceProviderDetails?.profile_pic !== undefined &&
          serviceProviderDetails?.profile_pic !== "" &&
          serviceProviderDetails?.profile_pic !== "null" &&
          serviceProviderDetails?.profile_pic !== "NULL"
           ? (
            <img
              src={serviceProviderDetails?.profile_pic}
              alt={displayName}
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          ) : (
            <span>{initial}</span>
          )}
        </div>
        <div className={Styles.profileName}>
           by <span className={Styles.displayName}>{displayName}</span>
        </div>
      </div>
      <button
        onClick={followFunctionHandler}
        className={Styles.followButton}
        disabled={userRole === "guest" ? true : false}
      >
        Follow
      </button>
    </div>
    </>
  );
};

export default ServiceProviderCard;
