import React, { useState, useEffect } from "react";
import { getShortenText, getCapitalize } from "../../../utils/helpers";
import RatingStar from "../../../assests/customIcons/RatingStar";
import Octopus from "../../../assests/customIcons/Octopus";
import "./TopCard.css";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import { getSupplierProfile } from "../../../redux/actions/SupplierProfile";
import SupplierDrawer from "../../../components/Drawer/SupplierDrawer";
import BrandDrawer from "../../../components/Drawer/BrandDrawer";

import LoginPopUp from "../../../pages/Login/LoginPopup";

const TopCard = React.memo(({ topItem, enableClick, userType }: any) => {
  console.log("🚀 ~ topItem Suppliers:", topItem);

  const dispatch = useDispatch<any>();
  const [category, setCategory] = useState("");
  const [openDrawer, setopenDrawer] = useState<any>(false);
  // const [openSupplierDrawer, setopenSupplierDrawer] = useState<any>(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);

  const [openSupplierDrawer, setOpenSupplierDrawer] = useState(false);
  const [openBrandDrawer, setOpenBrandDrawer] = useState(false);


  const transformSupplierData = (data: any) => {
    if (data.role !== "supplier") {
      return null;
    }

    return {
      id: data.supplier_id || data.id,
      name: data.supplier_display_name || `${data.first_name} ${data.last_name}`,
      image: data.supplier_display_image || data.profile_pic,
      rating: data.user_rating || 0,
      totalCollaborations: parseInt(data.totalcollaborations || "0", 10),
      totalProducts: parseInt(data.totalproducts || "0", 10),
      totalBrands: parseInt(data.totalbrands || "0", 10),
      products: (data.products || []).map((product: any) => ({
        id: product.id,
        name: product.name,
        image: product.image[0] || "",
        price: product.price || 0,
        rating: parseFloat(product.rating || "0"),
      })),
      brands: (data.brands || []).map((brand: any) => ({
        id: brand.id,
        name: brand.name,
        logo: brand.logo || "",
      })),
      stores: (data.stores || []).map((store: any) => ({
        id: store.id,
        name: store.name,
        logo: store.logo || "",
      })),
    };
  };

  const transformedSupplier =
    userType === "supplier" ? transformSupplierData(topItem) : null;

  const getCategory = () => {
    if (topItem.user_categories && topItem.user_categories.includes("{")) {
      const objstring = topItem.user_categories;
      const arrstring =
        "[" + objstring.substring(1, objstring.length - 1) + "]";
      const categoryArr = JSON.parse(arrstring);
      const firstCategory = categoryArr[0];
      setCategory(getShortenText(firstCategory, 15));
    }
  };

  const rating = 4.5;
  const token = localStorage.getItem("jwtToken");

  useEffect(() => {
    getCategory();
  }, []);

  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(_.get(topItem, "id", ""), (value: any) => {
        setopenDrawer(value);
      })
    );
  };
  const onSupplierProfileClickHandler = async () => {
    // await dispatch(
    //   getSupplierProfile(_.get(topItem, "id", ""), (value: any) => {
    setOpenSupplierDrawer(true);
    // })
    // );
  };

  const handleClick = () => {
    if (userType === "supplier") {
      onSupplierProfileClickHandler();
    } else if (userType === "freelancer") {
      onFreelancerProfileClickHandler();
    } else {
      setOpenBrandDrawer(true);
    }
  };

  const name = getShortenText(
    topItem.supplier_display_name || topItem.first_name || "Octopus",
    12
  );

  const avatarLetter = (topItem.supplier_display_name || topItem.first_name || "O")
    .charAt(0)
    .toUpperCase();

  return (
    <>
      <div className="topCardContainer" onClick={handleClick}>
        <div style={{ position: "relative" }}>
          {topItem.supplier_display_image && topItem.supplier_display_image !== "NULL" ? (
            <img className="topImage" src={topItem.supplier_display_image} alt="profile" />
          ) : (
            <div
              className="topImage"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f0f0f0",
                color: "#555",
                fontSize: "20px",
                fontWeight: "bold",
                borderRadius: "50%",
              }}
            >
              {avatarLetter}
            </div>
          )}
          {rating && (
            <div className="ratingContainer">
              <RatingStar />
              <p className="ratingText">{topItem?.user_rating}</p>
            </div>
          )}
        </div>
        <div className="topInfo" onClick={handleClick}>
          <p className="mainTitle" style={{ marginTop: rating ? "15px" : 0 }}>
            {name}
          </p>
          {category && <p className="subTitle">{category}</p>}
          <p className="extraText">
            {_.get(
              topItem,
              userType === "freelancer"
                ? "octopus_follower_count"
                : "totalcollaborations",
              "0"
            )}
            &nbsp;{userType === "freelancer" ? "Followers" : "Collaborations"}
          </p>
        </div>
      </div>
      <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
      {transformedSupplier && (
        <SupplierDrawer
          open={openSupplierDrawer}
          onClose={() => setOpenSupplierDrawer(false)}
          supplier={transformedSupplier}
        />
      )}
      {/* <BrandDrawer
        open={openBrandDrawer}
        onClose={() => setOpenBrandDrawer(false)}
        brand={sampleBrand}
      /> */}
    </>
  );
});

export default TopCard;
